import { alpha, styled, Box, ClickAwayListener, LinearProgress } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  gridClasses,
  GridRowClassNameParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { useDataGridExtensionsContext } from "app/contexts/DataGridExtensions";
import { IrisColors } from "app/mui/theme";
import React from "react";
import { TableSkeleton } from "../common/skeletons/TableSkeleton";

const ODD_OPACITY = 0.1;

/**
 * A striped version of the mui datagrid.
 * Borrowed from the docs here: https://mui.com/x/react-data-grid/style/#striped-rows
 */
const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  ["& .MuiDataGrid-pinnedColumnHeaders"]: {
    background: theme.palette.common.white,
  },
  ["& .MuiDataGrid-pinnedColumns"]: {
    background: theme.palette.common.white,
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.common.white,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(IrisColors.blue, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(IrisColors.blue, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          IrisColors.blue,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[100],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(IrisColors.blue, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(IrisColors.blue, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          IrisColors.blue,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const getRowClassName = (params: GridRowClassNameParams<GridValidRowModel>) =>
  params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even";

const experimentalFeatures = { newEditingApi: true };

const initialState = {
  columns: { columnVisibilityModel: {}, orderedFields: [], dimensions: {} },
};

export const StripedDataGrid = React.memo((props: DataGridProProps) => {
  const { columns, ...rest } = props;
  const memoizedColumns = React.useMemo(() => columns, []);
  const dataGridExtensions = useDataGridExtensionsContext();

  const [selectionModel, setSelectionModel] = React.useState([]);
  const handleBlur = () => {
    setSelectionModel([]);
  };
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
  };

  return (
    <>
      {dataGridExtensions.isLoading && <TableSkeleton />}
      <Box height="700px" display={dataGridExtensions.isLoading ? "none" : "block"}>
        <ClickAwayListener onClickAway={handleBlur}>
          <StyledDataGrid
            getRowClassName={getRowClassName}
            experimentalFeatures={experimentalFeatures}
            initialState={props.initialState ?? initialState}
            columns={memoizedColumns}
            onSelectionModelChange={handleSelectionModelChange}
            selectionModel={selectionModel}
            {...rest}
          />
        </ClickAwayListener>
      </Box>
    </>
  );
});
