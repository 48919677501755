import { AssetDigest } from "assets";
import { RecordFlagType } from "flags";
import { Keyword } from "keywords";
import { KpiValueTotals } from "kpis";
import { RecordOutageDetailView } from "records/bs/outage/models";
import { Health, Status, VisualIntegrity } from "records/models";
import { RecordsEventDigest } from "recordsEvents";
import { User } from "users";
import { DiagnosticType } from "./diagnosticType";
import { Recommendation } from "./recommendation";
import { RecordType } from "./recordType";
import { ReportCardPneumaticFullStroke } from "./reportCardPneumaticFullStroke";
import { ReportCardPneumaticPartialStroke } from "./reportCardPneumaticPartialStroke";
import { ReportCardMotorOperated } from "./reportCardMotorOperated";
import { ReportCardVibrationAnalysis } from "./reportCardVibrationAnalysis";

export interface RecordDigest {
  id: number;
  siteId: number;
  recordType: RecordType;
  description: string;
  status: Status;
}

export interface RecordDetailView {
  id: number;
  siteId: number;

  attachmentCount: number;
  closedBy: User | null;
  created: Date | null;
  createdBy: User;
  lastModifiedBy: User | null;
  kpiValueTotals: KpiValueTotals | null;
  lastModified: Date;
  outage: RecordOutageDetailView | null;
  model: Record;
  recommendations: Recommendation[];
  recordsEvent: RecordsEventDigest | null;

  reportCardPneumaticFullStroke: ReportCardPneumaticFullStroke | null;
  reportCardPneumaticPartialStroke: ReportCardPneumaticPartialStroke | null;
  reportCardMotorOperated: ReportCardMotorOperated | null;
  reportCardVibrationAnalysis: ReportCardVibrationAnalysis | null;
}

export interface Record {
  id: number;
  siteId: number;
  recordType: RecordType;
  diagnosticType: DiagnosticType;

  description: string;
  details: string;
  refWO: string | null;
  status: Status;
  closed: Date | null;

  followUp: Date | null;
  failureModeId: number | null;
  testCriteria: string | null;
  testResults: string | null;
  observations: string | null;
  eventDate: Date | null;
  recordsEventId: number | null;

  customerContact: string | null;

  health: Health;
  asLeftVisualIntegrity: VisualIntegrity;
  asFoundVisualIntegrity: VisualIntegrity;

  assets: AssetDigest[];
  flags: RecordFlagType[];
  keywords: Keyword[];
}

export enum RecordFieldLabel {
  Id = "ID",
  Description = "Description",
  RecordType = "Type",
  Status = "Status",
  DiagnosticType = "Diagnostic type",
  Created = "Created",
  CreatedByFullName = "Created by",
  FollowUp = "Follow-up",
  Details = "Details",
  Assets = "Asset(s)",
  Flags = "Flags",
  Keywords = "Keywords",
  TestCriteria = "Test criteria",
  TestResults = "Test results",
  Observations = "Observations",
  EventDate = "Occurrence date",
  CustomerContact = "Customer contact",
  Recommendations = "Recommendations",
  RefWo = "Ref. WO/MOC",
  FailureMode = "Failure mode",
  Closed = "Closed",
  RecordsEventName = "Event name",
  Notes = "Notes",
}

export enum ParentRecordFieldLabel {
  description = "Record",
  recordType = "Record type",
  status = "Record status",
  recordFlags = "Record flags",
  recordKeywords = "Record keywords",
  refWO = "Record ref. WO/MOC",
}
