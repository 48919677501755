import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { BaseDialog } from "app/mui/common/dialogs/BaseDialog";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { AttachmentParent, useUpdateAttachmentRequest } from "attachments/api";
import { Attachment } from "attachments/models";
import { useForm } from "react-hook-form";
import { useCanEditAttachment } from "users";

interface ShowAttachmentModalProps {
  attachmentType: AttachmentParent;
  attachment: Attachment;
  onClose: () => void;
  scrollLeft: () => void;
  scrollRight: () => void;
}

export const ShowAttachmentModal = (props: ShowAttachmentModalProps) => {
  const methods = useForm({ defaultValues: { caption: props.attachment.caption } });
  const canEdit = useCanEditAttachment(props.attachmentType);

  const updateAttachmentRequest = useUpdateAttachmentRequest();
  const canEditCapiton = () => {
    const isAssetAssemblyImage =
      props.attachment.isPrimaryImage && props.attachment.caption.toLowerCase().includes("assembly");
    return canEdit && !isAssetAssemblyImage;
  };

  const onSave = () => {
    methods.handleSubmit((values) => {
      updateAttachmentRequest
        .call({
          attachment: { ...props.attachment, caption: values.caption ?? "" },
          rotate: false,
          copyToAsset: false,
        })
        .then(() => {
          props.onClose();
        });
    })();
  };

  return (
    <BaseDialog onClose={props.onClose} closeWithBackdrop>
      <DialogTitle
        variant="h6"
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {props.attachment.fileName}
      </DialogTitle>
      <Stack spacing={2}>
        <Box sx={{ position: "relative" }}>
          <img
            style={{ width: "100%", height: "100%", maxHeight: "500px", objectFit: "contain" }}
            src={props.attachment.uri}
            alt={props.attachment.caption || props.attachment.fileName}
          />

          <Box
            sx={{
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button variant="text" onClick={props.scrollLeft}>
              <ArrowBackIosRoundedIcon
                sx={{
                  fontSize: "62px",
                  color: "white",
                  stroke: "#00000075",
                }}
              />
            </Button>
            <Button variant="text" onClick={props.scrollRight}>
              <ArrowForwardIosRoundedIcon
                sx={{
                  fontSize: "62px",
                  color: "white",
                  stroke: "#00000075",
                }}
              />
            </Button>
          </Box>
        </Box>
        <DialogContent sx={{ mt: 0 }}>
          <PermissionAwareFormProvider {...methods} require={(_) => canEditCapiton()}>
            <TextFieldFormItem fieldName="caption" label="Caption" />
          </PermissionAwareFormProvider>
        </DialogContent>
      </Stack>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Close</Button>
          <Button variant="contained" color="secondary" onClick={onSave} disabled={!canEdit}>
            Save
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
