import { Grid } from "@mui/material";
import AutoSave from "app/mui/forms/AutoSave";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { IrisColors } from "app/mui/theme";
import {
  KpiOneTimeDowntimeAvoidance,
  KpiOneTimeLabourOrEquipmentCostSavings,
  KpiOneTimeScheduleDelayReduction,
  KpiOngoingEmissionsReduction,
  KpiOngoingMaintenanceSavingsOrProductionImprovement,
  KpiTemplateDetail,
  KpiTemplateModel,
  TemplateKpiType,
} from "kpis";
import KpiFooter, { kpiFooterValidationSchema } from "kpis/mui/forms/KpiFooter";
import KpiOneTimeDowntimeAvoidanceDetails from "kpis/mui/forms/KpiOneTimeDowntimeAvoidance/KpiOneTimeDowntimeAvoidanceDetails";
import {
  KpiOneTimeDowntimeAvoidanceValidationSchema,
  SimplifiedKpiOneTimeDowntimeAvoidanceValidationSchema,
} from "kpis/mui/forms/KpiOneTimeDowntimeAvoidance/KpiOneTimeDowntimeAvoidanceValidationSchema";
import { KpiOneTimeLabourOrEquipmentCostSavingsDetails } from "kpis/mui/forms/KpiOneTimeLabourOrEquipmentCostSavings/KpiOneTimeLabourOrEquipmentCostSavingsDetails";
import {
  KpiOneTimeLabourOrEquipmentCostSavingsValidationSchema,
  SimplifiedKpiOneTimeLabourOrEquipmentCostSavingsValidationSchema,
} from "kpis/mui/forms/KpiOneTimeLabourOrEquipmentCostSavings/KpiOneTimeLabourOrEquipmentCostSavingsValidationSchema";
import KpiOneTimeReleaseOrSpillAvoidanceDetails from "kpis/mui/forms/KpiOneTimeReleaseOrSpillAvoidance/KpiOneTimeReleaseOrSpillAvoidanceDetails";
import { KpiOneTimeReleaseOrSpillAvoidanceValidationSchema } from "kpis/mui/forms/KpiOneTimeReleaseOrSpillAvoidance/KpiOneTimeReleaseOrSpillAvoidanceValidationSchema";
import { KpiOneTimeScheduleDelayReductionDetails } from "kpis/mui/forms/KpiOneTimeScheduleDelayReduction/KpiOneTimeScheduleDelayReductionDetails";
import { KpiOneTimeScheduleDelayReductionValidationSchema } from "kpis/mui/forms/KpiOneTimeScheduleDelayReduction/KpiOneTimeScheduleDelayReductionValidationSchema";
import KpiOngoingEmissionsReductionDetails from "kpis/mui/forms/KpiOngoingEmissionsReduction/KpiOngoingEmissionsReductionDetails";
import { KpiOngoingEmissionsReductionValidationSchema } from "kpis/mui/forms/KpiOngoingEmissionsReduction/KpiOngoingEmissionsReductionValidationSchema";
import KpiOngoingMaintenanceSavingsOrProductionImprovementDetails from "kpis/mui/forms/KpiOngoingMaintenanceSavingsOrProductionImprovement/KpiOngoingMaintenanceSavingsOrProductionImprovementDetails";
import {
  KpiOngoingMaintenanceSavingsOrProductionImprovementValidationSchema,
  SimplifiedKpiOngoingMaintenanceSavingsOrProductionImprovementValidationSchema,
} from "kpis/mui/forms/KpiOngoingMaintenanceSavingsOrProductionImprovement/KpiOngoingMaintenanceSavingsOrProductionImprovementValidationSchema";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

const _getValidationSchema = (kpiType: TemplateKpiType, isSimplified: boolean = false) => {
  switch (kpiType) {
    case "OneTimeDowntimeAvoidance":
      return isSimplified
        ? SimplifiedKpiOneTimeDowntimeAvoidanceValidationSchema
        : KpiOneTimeDowntimeAvoidanceValidationSchema;
    case "OneTimeLabourOrEquipmentCostSavings":
      return isSimplified
        ? SimplifiedKpiOneTimeLabourOrEquipmentCostSavingsValidationSchema
        : KpiOneTimeLabourOrEquipmentCostSavingsValidationSchema;
    case "OneTimeReleaseOrSpillAvoidance":
      return KpiOneTimeReleaseOrSpillAvoidanceValidationSchema;
    case "OneTimeScheduleDelayReduction":
      return KpiOneTimeScheduleDelayReductionValidationSchema;
    case "OngoingEmissionsReduction":
      return KpiOngoingEmissionsReductionValidationSchema;
    case "OngoingMaintenanceSavingsOrProductionImprovement":
      return isSimplified
        ? SimplifiedKpiOngoingMaintenanceSavingsOrProductionImprovementValidationSchema
        : KpiOngoingMaintenanceSavingsOrProductionImprovementValidationSchema;
    default:
      console.warn("No validation schema set");
      return yup.object({});
  }
};

const getValidationSchema = (kpiType: TemplateKpiType, isSimplified: boolean) => {
  const formSchema = _getValidationSchema(kpiType, isSimplified);
  return formSchema.concat(kpiFooterValidationSchema);
};

const getDefaultValues = (model: KpiTemplateModel) => {
  switch (model.kpiType) {
    case "OneTimeDowntimeAvoidance":
      return makeSafeForReactHookForm(model as KpiOneTimeDowntimeAvoidance);
    case "OneTimeLabourOrEquipmentCostSavings":
      return makeSafeForReactHookForm(model as KpiOneTimeLabourOrEquipmentCostSavings);
    case "OneTimeScheduleDelayReduction":
      return makeSafeForReactHookForm(model as KpiOneTimeScheduleDelayReduction);
    case "OngoingEmissionsReduction":
      return makeSafeForReactHookForm(model as KpiOngoingEmissionsReduction);
    case "OngoingMaintenanceSavingsOrProductionImprovement":
      return makeSafeForReactHookForm(model as KpiOngoingMaintenanceSavingsOrProductionImprovement);
    default:
      throw new Error("Unrecognized Kpi Template Type");
  }
};

interface KpiTemplateDetailFormProps {
  readOnly: boolean;
  kpiTemplateDetail: KpiTemplateDetail;
  userCanEdit: boolean;
  userCanUnlock: boolean;
  saveChanges: (values: Partial<KpiTemplateModel>) => void;
}

export function KpiTemplateDetailForm(props: KpiTemplateDetailFormProps) {
  const {
    kpiTemplateDetail: {
      model: { kpiType },
    },
    userCanUnlock,
    saveChanges,
  } = props;

  const formDefaultValues = getDefaultValues(props.kpiTemplateDetail.model);
  const methods = useForm<Partial<KpiTemplateModel>>({
    defaultValues: formDefaultValues,
  });

  const isSimplifiedValue = methods.watch("isSimplifiedVersion") ?? false;

  const validationSchema = useMemo(() => getValidationSchema(kpiType, isSimplifiedValue), [kpiType, isSimplifiedValue]);

  useEffect(() => {
    const formDefaultValues = getDefaultValues(props.kpiTemplateDetail.model);
    methods.reset(formDefaultValues);
  }, [props.kpiTemplateDetail.model.id]);

  const onUpdated = () => {
    methods.handleSubmit((values) => {
      saveChanges(values);
    })();
  };

  const handleSignOff = async () => {
    try {
      await validationSchema.validate(methods.getValues(), { abortEarly: false });
      methods.setValue("signedOff", true);
      saveChanges(methods.getValues());
    } catch (errors: any) {
      errors.inner.forEach((currentError) => {
        methods.setError(currentError.path, currentError);
      });
    }
  };

  const handleRemoveSignOff = async () => {
    if (!userCanUnlock) {
      return;
    }
    methods.setValue("signedOff", false);
    methods.setValue("customerSignOff", null);
    methods.setValue("signOffDate", null);
    saveChanges(methods.getValues());
  };

  const editKpiFormProps = useMemo(
    () => ({
      detail: props.kpiTemplateDetail,
      model: props.kpiTemplateDetail.model,
      readOnly: props.readOnly || props.kpiTemplateDetail.model.signedOff || !props.userCanEdit,
      userCanEdit: props.userCanEdit,
    }),
    [props.kpiTemplateDetail.model, props.readOnly, props.userCanEdit]
  );

  return (
    <FormProvider {...methods}>
      <AutoSave defaultValues={formDefaultValues} onSubmit={onUpdated} />
      <Grid container>
        <Grid item xs={12}>
          {kpiType === "OneTimeDowntimeAvoidance" && <KpiOneTimeDowntimeAvoidanceDetails {...editKpiFormProps} />}
          {kpiType === "OneTimeLabourOrEquipmentCostSavings" && (
            <KpiOneTimeLabourOrEquipmentCostSavingsDetails {...editKpiFormProps} />
          )}
          {kpiType === "OneTimeReleaseOrSpillAvoidance" && (
            <KpiOneTimeReleaseOrSpillAvoidanceDetails {...editKpiFormProps} />
          )}
          {kpiType === "OngoingEmissionsReduction" && <KpiOngoingEmissionsReductionDetails {...editKpiFormProps} />}
          {kpiType === "OneTimeScheduleDelayReduction" && (
            <KpiOneTimeScheduleDelayReductionDetails {...editKpiFormProps} />
          )}
          {kpiType === "OngoingMaintenanceSavingsOrProductionImprovement" && (
            <KpiOngoingMaintenanceSavingsOrProductionImprovementDetails {...editKpiFormProps} />
          )}
        </Grid>
        <Grid item xs={12} mt={5} pt={3} borderTop={`1px solid ${IrisColors.gray300}`}>
          <KpiFooter
            userCanUnlock={userCanUnlock}
            signOff={handleSignOff}
            removeSignOff={handleRemoveSignOff}
            formItemProps={editKpiFormProps}
          />
        </Grid>
      </Grid>
    </FormProvider>
  );
}
