import { Box, Divider, Link, Paper, Stack, Typography } from "@mui/material";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import { Link as RouterLink } from "react-router-dom";
import { CustomerDashboardViewModel, RecentWin } from "dashboard/models";
import { FunctionComponent } from "react";
import { formatRoutePath, Routes } from "app";
import { dateFormat, LinkColumn, LinkColumnType } from "common";
import moment from "moment";
import { IrisColors } from "app/mui/theme";
import { NotEnoughData } from "app/mui/common/placeholders/NotEnoughDataYetMessage";
import { WidgetSkeleton } from "dashboard/widgets/WidgetSkeleton";

interface WinsWidgetProps {
	data?: CustomerDashboardViewModel;
}

export const WinsWidget: FunctionComponent<WinsWidgetProps> = ({ data }) => {
	return (
		<Paper sx={{ p: { xs: 2, md: 3 }, width: "100%", height: "636px" }}>
			{!!!data ? (
				<WidgetSkeleton></WidgetSkeleton>
			) : (
				<Stack spacing={4} sx={{ height: "100%" }}>
					<Typography variant="h5">Recent Wins</Typography>
					<Stack spacing={3} sx={{ height: "100%", overflowY: "scroll" }}>
						{data.recentWins.length === 0 && <NotEnoughData />}
						{data.recentWins.map((recentWin) => (
							<RecentWinLineItem recentWin={recentWin} key={recentWin.link.id} />
						))}
					</Stack>
				</Stack>
			)}
		</Paper>
	);
};

const RecentWinLineItem = ({ recentWin }: { recentWin: RecentWin }) => {
	if (recentWin.link.type === LinkColumnType.Summary) {
		return <RecentWinSummaryReport recentWin={recentWin} />;
	} else if (recentWin.link.type === LinkColumnType.Record) {
		return <RecentWinValveDiagnostic recentWin={recentWin} />;
	} else {
		return null;
	}
};

const RecentWinSummaryReport = ({ recentWin }: { recentWin: RecentWin }) => {
	return (
		<RecentWinBase
			recentWin={recentWin}
			type={`Summary Report: ${recentWin.type}`}
			renderIcon={() => <AssessmentRoundedIcon sx={{ fontSize: "2.4em" }} />}
		/>
	);
};

const RecentWinValveDiagnostic = ({ recentWin }: { recentWin: RecentWin }) => {
	return (
		<RecentWinBase
			recentWin={recentWin}
			type={"Valve health improvement"}
			renderIcon={() => (
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Typography sx={{ fontSize: "1.3rem" }}>+</Typography>
					<Typography sx={{ fontSize: "1.6rem", fontWeight: "bold" }} component="span">
						{recentWin.figure}
					</Typography>
					<Typography sx={{ fontSize: "1.3rem" }}>%</Typography>
				</Box>
			)}
		/>
	);
};

const RecentWinBase = ({
	recentWin,
	renderIcon,
	type,
}: {
	recentWin: RecentWin;
	renderIcon: () => React.ReactNode;
	type: string;
}) => {
	return (
		<Stack direction="row" spacing={0}>
			<Box
				sx={{
					color: IrisColors.green,
					display: "flex",
					justifyContent: "center",
					alignItems: "flex-start",
					pt: 1,
					mr: 3 / 2,
					width: "70px",
					flexShrink: 0,
				}}
			>
				{renderIcon()}
			</Box>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Stack spacing={0} sx={{ width: "100%" }}>
					<LinkToWin link={recentWin.link} />
					<Typography variant="body2" fontWeight="600">
						{recentWin.site.name}
					</Typography>
					<Typography variant="body2" fontWeight="600">
						<Typography
							variant="body2"
							fontWeight="600"
							component="span"
							sx={{ color: IrisColors.gray600 }}
						>
							{`${type} • `}
						</Typography>
						<Typography variant="body2" fontWeight="600" component="span">
							{moment(recentWin.date).format(dateFormat)}
						</Typography>
					</Typography>
				</Stack>
				<Divider />
			</Stack>
		</Stack>
	);
};

const LinkToWin = ({ link }: { link: LinkColumn }) => {
	return (
		<Typography fontWeight="bold">
			<Link
				to={formatRoutePath(Routes[link.type], { siteId: link.siteId, id: link.id })}
				component={RouterLink}
				color="secondary"
			>
				{link.name}
			</Link>
		</Typography>
	);
};
