import { Box, LinearProgress } from "@mui/material";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { ActionDispatcherApi } from "app/mui/ActionDispatcher";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { createStaticRowActions, useStaticRowActions } from "app/mui/tables/utils/createStaticColumns";
import { DeleteIcon } from "icons/DeleteIcon";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { RecordDetailView } from "records";
import { SummaryDetail, Summary } from "summaries/models";

const rowActions = (summary: SummaryDetail, methods: UseFormReturn<Summary>, saveChanges: () => void) =>
  createStaticRowActions((actionDispatcher: ActionDispatcherApi, permissions: any) => (params: any) => [
    {
      icon: <DeleteIcon />,
      onClick: () => {
        const updatedRecords = summary.model.records.filter((record) => record.id !== params.row.id);
        methods.setValue('records', updatedRecords);
        saveChanges();
      },
      label: "Remove Record",
      showInMenu: true,
      hidden: !permissions.userCanEditRecords,
    },
  ]);

interface SummaryRecordsTableProps {
  saveChanges: () => void;
  isLoading: boolean;
  apiRef: React.MutableRefObject<GridApiPro> | undefined;
  data: RecordDetailView[];
  summaryDetail: SummaryDetail;
  showActions?: true;
}

export const SummaryRecordsTable = (props: SummaryRecordsTableProps) => {
  const methods = useFormContext<Summary>();
  const getTableRowActions = useStaticRowActions(rowActions(props.summaryDetail, methods, props.saveChanges));

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <MetadataColumnProvider type="RecordDetailViewModel" getActions={props.showActions ? getTableRowActions : undefined}>
        {(columns) => (
          <StripedDataGrid
            apiRef={props.apiRef}
            density="compact"
            components={GridComponents}
            columns={columns}
            rows={props.data}
            loading={props.isLoading}
          />
        )}
      </MetadataColumnProvider>
    </Box>
  );
};

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("Summary-Records"),
};