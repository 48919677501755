import { ApplicationList } from "applications/ApplicationList";
import { ApplicationScreen } from "applications/ApplicationScreen";
import { ManageKeywords } from "keywords/ManageKeywords";

import { Routes as routes, useUserContext } from "app";
import { NewLayout } from "app/mui/NewLayout";
import { AssetPage } from "assets/mui/AssetPage";
import { AssetsListPage } from "assets/mui/AssetsListPage";
import { HelpPage } from "help";
import { ImportCsvPage } from "importCsv/ImportCsvPage";
import { KpiTemplateList } from "kpis/KpiTemplateList";
import { KpiTemplateScreenQuery } from "kpis/KpiTemplateScreenQuery";
import { CustomersPage } from "sites/manage/mui/CustomersPage";
import { MySitesPage } from "sites/pages/MySitesPage";
import { CustomerDashboardPage, SiteDashboardPage } from "../dashboard";

// react router migration guide
// https://github.com/remix-run/react-router/discussions/8753
import { Route, Routes } from "react-router-dom";

// legacy pages still depend on css
import "./bs/styles/index.scss";
// import "./bs/styles/index.scss";
import { FeatureFlagName, useFeatureEnabled } from "common/models";
import { ImportDataPage } from "importData/ImportDataPage";
import { MachineryComponentsListPage } from "machinery/MachineryComponentsListPage";
import { useEffect } from "react";
import { RecordPage } from "records/mui/RecordPage";
import { RecordsEventList } from "recordsEvents/bs/RecordsEventList";
import { RecordsEventScreenQuery } from "recordsEvents/bs/RecordsEventScreenQuery";
import { EventListPage } from "recordsEvents/mui/EventListPage";
import { RecordsEventPage } from "recordsEvents/mui/RecordsEventPage";
import { SummaryList } from "summaries/bs/SummaryList";
import { SummaryScreenQuery } from "summaries/bs/SummaryScreenQuery";
import { SummaryListPage } from "summaries/mui/SummaryListPage";
import { SummaryPage } from "summaries/mui/SummaryPage";
import { TasksListPage } from "tasks/mui/TasksListPage";
import { useAppSettingsContext } from "./contexts/AppSettingsContext";
import { RedirectToFirstCustomer } from "./RedirectToFirstCustomer";
import { KpiTemplateListPage } from "kpis/mui/KpiTemplatesListPage";
import { KpiTemplatePage } from "kpis/mui/KpiTemplatePage";

export const AppRoutes = () => {
  var { userPermissions, currentUserLoading } = useUserContext();
  const isEventsLayoutV2Enabled = useFeatureEnabled(FeatureFlagName.UseEventsLayoutV2);
  const isSummariesLayoutV2Enabled = useFeatureEnabled(FeatureFlagName.UseSummariesLayoutV2);
  const isKpiTemplatesLayoutV2Enabled = useFeatureEnabled(FeatureFlagName.UseKpiTemplatesLayoutV2);
  const isImportDataLayout = useFeatureEnabled(FeatureFlagName.UseImportDataLayout);
  const { appSettings } = useAppSettingsContext();

  const applyFavIcon = () => {
    let favIconLink = document.querySelector("link[rel~='icon']");
    if (!favIconLink) {
      favIconLink = document.createElement("link");
      favIconLink["rel"] = "icon";
      document.getElementsByTagName("head")[0].appendChild(favIconLink);
    }
    favIconLink["href"] = appSettings?.irisFavIconUrl;
  };

  useEffect(() => {
    if (appSettings?.irisFavIconUrl) {
      applyFavIcon();
    }
  }, [appSettings?.irisFavIconUrl]);

  if (currentUserLoading) return null;

  return (
    <NewLayout>
      <Routes>
        <Route path={routes.Sites} element={<MySitesPage />} />
        {(userPermissions.userCanManageCustomersAndSites ||
          userPermissions.userCanAssignUsersToSites ||
          userPermissions.userCanManageAreas) && <Route path={routes.Customers} element={<CustomersPage />} />}
        {userPermissions.userCanManageKeywords && <Route path={routes.ManageKeywords} element={<ManageKeywords />} />}
        <Route path={routes.CustomerDashboard} element={<CustomerDashboardPage />} />
        <Route path={routes.SiteDashboard} element={<SiteDashboardPage />} />
        {userPermissions.userCanViewAsset && <Route path={routes.Asset} element={<AssetPage />} />}
        {userPermissions.userCanViewAssetList && <Route path={routes.Assets} element={<AssetsListPage />} />}
        {userPermissions.userCanViewAssetList && <Route path={routes.FilteredAssets} element={<AssetsListPage />} />}
        {userPermissions.userCanViewAssetList && (
          <Route path={routes.MachineryComponents} element={<MachineryComponentsListPage />} />
        )}

        {userPermissions.userCanViewRecordList && <Route path={routes.Tasks} element={<TasksListPage />} />}
        {userPermissions.userCanViewRecordList && <Route path={routes.Records} element={<TasksListPage />} />}
        {userPermissions.userCanViewRecordList && <Route path={routes.Recommendations} element={<TasksListPage />} />}
        {userPermissions.userCanViewRecordList && <Route path={routes.Kpis} element={<TasksListPage />} />}
        {userPermissions.userCanViewRecord && <Route path={routes.Record} element={<RecordPage />} />}
        {userPermissions.userCanViewApplication && <Route path={routes.Application} element={<ApplicationScreen />} />}
        {userPermissions.userCanViewApplication && <Route path={routes.Applications} element={<ApplicationList />} />}
        {userPermissions.userCanImportAssetsAndRecords && (
          <Route path={routes.ImportCsv} element={isImportDataLayout ? <ImportDataPage /> : <ImportCsvPage />} />
        )}
        {userPermissions.userCanImportAssetsAndRecords && (
          <Route path={routes.ImportData} element={<ImportDataPage />} />
        )}
        {userPermissions.userCanImportAssetsAndRecords && (
          <Route path={routes.ImportOffline} element={<ImportDataPage />} />
        )}
        {userPermissions.userCanImportAssetsAndRecords && (
          <Route path={routes.ImportOfflineCreate} element={<ImportDataPage />} />
        )}
        {userPermissions.userCanImportAssetsAndRecords && (
          <Route path={routes.ImportOfflineConfirm} element={<ImportDataPage />} />
        )}
        {userPermissions.userCanViewSummary && (
          <Route
            path={routes.Summary}
            element={isSummariesLayoutV2Enabled ? <SummaryPage /> : <SummaryScreenQuery />}
          />
        )}
        {userPermissions.userCanViewSummary && (
          <Route path={routes.Summaries} element={isSummariesLayoutV2Enabled ? <SummaryListPage /> : <SummaryList />} />
        )}
        {userPermissions.userCanViewSummary && <Route path={routes.SummariesLegacy} element={<SummaryList />} />}
        {userPermissions.userCanViewRecord && (
          <Route
            path={routes.RecordsEvent}
            element={isEventsLayoutV2Enabled ? <RecordsEventPage /> : <RecordsEventScreenQuery />}
          />
        )}
        {userPermissions.userCanViewRecordList && (
          <Route
            path={routes.RecordsEvents}
            element={isEventsLayoutV2Enabled ? <EventListPage /> : <RecordsEventList />}
          />
        )}
        {userPermissions.userCanViewRecord && (
          <Route path={routes.RecordsEventsLegacy} element={<RecordsEventList />} />
        )}
        {userPermissions.userCanViewKpiTemplate && (
          <Route
            path={routes.KpiTemplate}
            element={isKpiTemplatesLayoutV2Enabled ? <KpiTemplatePage /> : <KpiTemplateScreenQuery />}
          />
        )}
        {userPermissions.userCanViewKpiTemplate && (
          <Route path={routes.KpiTemplates} element={<KpiTemplateListPage />} />
        )}
        <Route path="/tutorials" element={<HelpPage />} />
        <Route path="/" element={<RedirectToFirstCustomer />} />
      </Routes>
    </NewLayout>
  );
};
