import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Rotate90DegreesCwRoundedIcon from "@mui/icons-material/Rotate90DegreesCwRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { IrisColors } from "app/mui/theme";
import { AttachmentParent, useDeleteAttachmentRequest, useUpdateAttachmentRequest } from "attachments/api";
import { Attachment } from "attachments/models";
import { attachmentIsImage, downloadAttachment } from "attachments/utilities";
import { useCanEditAttachment } from "users";

interface AttachmentItemMenuProps {
	attachment: Attachment;
	anchorElement: HTMLElement | null;
	onClose: () => void;
	onRequestEdit: (attachment: Attachment) => void;
	onSetPrimaryImage: (attachment: Attachment) => void;
	parentType: AttachmentParent;
	parentId: number;
	onDeleteAttachment: (id: number) => void;
	canCopyAssembleyImage: boolean;
}

export const AttachmentItemMenu = (props: AttachmentItemMenuProps) => {
	const updateAttachmentRequest = useUpdateAttachmentRequest();
	const deleteAttachmentRequest = useDeleteAttachmentRequest(props.parentType, props.parentId);
	const canEdit = useCanEditAttachment(props.parentType);

	const edit = () => {
		props.onRequestEdit(props.attachment);
		props.onClose();
	};

	const setAsAssemblyImage = () => {
		if (!props.canCopyAssembleyImage || props.attachment.isPrimaryImage) {
			updateAttachmentRequest
				.call({
					attachment: { ...props.attachment, isPrimaryImage: !props.attachment.isPrimaryImage },
					rotate: false,
					copyToAsset: false,
				})
				.then(() => props.onClose());
		} else {
			props.onSetPrimaryImage(props.attachment);
			props.onClose();
		}
	};

	const rotate = () => {
		updateAttachmentRequest.call({ attachment: props.attachment, rotate: true, copyToAsset: false });
		props.onClose();
	};

	const download = async () => {
		await downloadAttachment(props.attachment);
		props.onClose();
	};

	const deleteAttachment = async () => {
		await deleteAttachmentRequest.call(props.attachment.id);
		props.onDeleteAttachment(props.attachment.id);
		props.onClose();
	};

	const canEditCaption = () => {
		const isAssetAssemblyImage =
			props.attachment.isPrimaryImage && props.attachment.caption.toLowerCase().includes("assembly");
		return canEdit && attachmentIsImage(props.attachment.fileName) && !isAssetAssemblyImage;
	};

	return (
		<Menu
			anchorEl={props.anchorElement}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={Boolean(props.anchorElement)}
			onClose={props.onClose}
			PaperProps={{
				style: {
					minWidth: 200,
				},
			}}
		>
			<MenuList>
				{canEditCaption() && (
					<MenuItem onClick={edit}>
						<ListItemIcon sx={{ svg: { fill: IrisColors.legacyTertiaryGray } }}>
							<EditRoundedIcon />
						</ListItemIcon>
						<ListItemText>Edit caption</ListItemText>
					</MenuItem>
				)}
				{canEdit && attachmentIsImage(props.attachment.fileName) && (
					<MenuItem onClick={rotate}>
						<ListItemIcon sx={{ svg: { fill: IrisColors.legacyTertiaryGray } }}>
							<Rotate90DegreesCwRoundedIcon />
						</ListItemIcon>
						<ListItemText>Rotate</ListItemText>
					</MenuItem>
				)}
				{canEdit && attachmentIsImage(props.attachment.fileName) && (
					<MenuItem onClick={setAsAssemblyImage}>
						<ListItemIcon sx={{ svg: { fill: IrisColors.legacyTertiaryGray } }}>
							{props.attachment.isPrimaryImage ? <StarOutlineRoundedIcon /> : <StarRoundedIcon />}
						</ListItemIcon>
						<ListItemText>
							{props.attachment.isPrimaryImage ? "Unset as assembly image" : "Set as assembly image"}
						</ListItemText>
					</MenuItem>
				)}
				<MenuItem onClick={download}>
					<ListItemIcon sx={{ svg: { fill: IrisColors.legacyTertiaryGray } }}>
						<DownloadRoundedIcon />
					</ListItemIcon>
					<ListItemText>Download</ListItemText>
				</MenuItem>
				<Divider />
				{canEdit && (
					<MenuItem onClick={deleteAttachment}>
						<ListItemIcon sx={{ svg: { fill: IrisColors.red } }}>
							<DeleteRoundedIcon />
						</ListItemIcon>
						<ListItemText sx={{ color: IrisColors.red }}>Delete attachment</ListItemText>
					</MenuItem>
				)}
			</MenuList>
		</Menu>
	);
};
