import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Box, Stack } from "@mui/material";
import { useGetRecordDetailsByRecordsEventQuery } from "records";
import { SummaryDetail } from "summaries/models";
import { useEffect } from "react";
import { SummaryRecordsTable } from "./SummaryRecordsTable";

export const SummaryRecordsEventRecordsTab = (props: {
  summaryDetail: SummaryDetail;
  saveChanges: () => void;
  setRecordsCount: (x: number) => void;
}) => {
  const apiRef = useGridApiRef();

  const getRecordDetailsByRecordEventQuery = useGetRecordDetailsByRecordsEventQuery(props.summaryDetail.model.recordsEventId);
  const records = getRecordDetailsByRecordEventQuery.data ?? [];
  const isLoading = getRecordDetailsByRecordEventQuery.loading;

  useEffect(() => {
    getRecordDetailsByRecordEventQuery.call();
  }, []); 

  useEffect(() => {
    if(isLoading === false) {
      props.setRecordsCount(records?.length ?? 0);
    }
  }, [isLoading])

  return (
    <Box sx={{ height: "500px" }}>
      <Stack spacing={2} height="100%">
        <SummaryRecordsTable
          saveChanges={props.saveChanges}
          apiRef={apiRef}
          data={records}
          summaryDetail={props.summaryDetail}
          isLoading={isLoading}
        />
      </Stack>
    </Box>
  );
}