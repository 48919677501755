import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { ActiveObsoleteStatusChip } from "common/mui/ActiveObsoleteStatusChip";
import { Controller, useFormContext } from "react-hook-form";
import { useEditableContext } from "./EditableContext";
import { useFormEditPermission } from "./PermissionAwareFormProvider";
import { ReadonlyFormItem } from "./ReadonlyFormItem";

export interface ActiveObsoleteFormItemProps {
  label: string;
  hint?: string;
  fieldName: string;
  convertToString?: true;
}

export const ActiveObsoleteFormItem = (props: ActiveObsoleteFormItemProps) => {
  const { hasPermissionToEdit } = useFormEditPermission();

  return (
    <Controller
      name={props.fieldName}
      defaultValue={false}
      render={({ field: { onChange, value, ...fieldProps }, fieldState }) => (
        <FormItemLayout controlled hint={props.hint} label={props.label}>
          {(inputProps) => (
            <ActiveObsoleteStatusChip
              isObsolete={props.convertToString ? value : value === "true"}
              onChange={(e) => onChange(props.convertToString ? (e === true ? "true" : "false") : e)}
              disabled={inputProps.disabled}
            />
          )}
        </FormItemLayout>
      )}
    />
  );
};

export const EditableActiveObsoleteFormItem = (props: ActiveObsoleteFormItemProps & { editable?: boolean }) => {
  const { editable: editableViaProps, ...rest } = props;
  const { editable: editableViaContext } = useEditableContext();
  const { watch } = useFormContext();
  const value = watch(props.fieldName);
  const valueAsString = value === "true" || value === true ? "Obsolete" : "Active";

  if (editableViaProps || editableViaContext) {
    return <ActiveObsoleteFormItem {...rest} />;
  }

  return <ReadonlyFormItem {...rest} value={valueAsString} />;
};
