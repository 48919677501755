import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Box, Stack } from "@mui/material";
import { usePostRecordsByIdQuery } from "records";
import { SummaryDetail } from "summaries/models";
import { useEffect } from "react";
import { SummaryRecordsTable } from "./SummaryRecordsTable";

export const SummaryRecordsTab = (props: {
  summaryDetail: SummaryDetail;
  saveChanges: () => void;
  setRecordsCount: (x: number) => void;
}) => {
  const apiRef = useGridApiRef();

  const getRecordDetailsByIdQuery = usePostRecordsByIdQuery();
  const records = getRecordDetailsByIdQuery.data ?? [];
  const isLoading = getRecordDetailsByIdQuery.loading;

  useEffect(() => {
    getRecordDetailsByIdQuery.call(props.summaryDetail.model.records.map((r) => r.id));
  }, [props.summaryDetail.model.records]); 

  useEffect(() => {
    if(isLoading === false) {
      props.setRecordsCount(records?.length ?? 0);
    }
  }, [isLoading])
  
  return (
    <Box sx={{ height: "500px" }}>
      <Stack spacing={2} height="100%">
        <SummaryRecordsTable
          saveChanges={props.saveChanges}
          apiRef={apiRef}
          data={records}
          summaryDetail={props.summaryDetail}
          showActions
          isLoading={isLoading}
        />
      </Stack>
    </Box>
  );
};