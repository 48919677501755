import { Box, LinearProgress, Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { ActionDispatcherApi, useActionDispatcher } from "app/mui/ActionDispatcher";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { KpiTemplateDetail } from "kpis/models";
import React, { useCallback, useEffect } from "react";
import { RecordDetailView, useGetRecordDetailsByKpiTemplateQuery } from "records";

interface KpiTemplateRecordsTabProps {
  kpiTemplateDetail: KpiTemplateDetail;
}

export const KpiTemplateRecordsTab: React.FC<KpiTemplateRecordsTabProps> = ({ kpiTemplateDetail }) => {
  const getRecordsEventByKpiTemplateRequest = useGetRecordDetailsByKpiTemplateQuery(kpiTemplateDetail.model.id);
  const recordDetails = getRecordsEventByKpiTemplateRequest.data ?? [];

  const actionDispatcher = useActionDispatcher();
  const apiRef = useGridApiRef();

  const handleAction = useCallback((action: string, params: any) => {}, []);

  useEffect(() => {
    getRecordsEventByKpiTemplateRequest.call();

    actionDispatcher.subscribe(handleAction);
    return () => actionDispatcher.unsubscribe(handleAction);
  }, []);

  return (
    <Box sx={{ height: "500px" }}>
      <Stack spacing={2} height="100%">
        <KpiTemplateRecordsTable
          kpiTemplateDetail={kpiTemplateDetail}
          actionDispatcher={actionDispatcher}
          apiRef={apiRef}
          data={recordDetails}
          loading={getRecordsEventByKpiTemplateRequest.loading}
        />
      </Stack>
    </Box>
  );
};

interface KpiTemplateRecordsTableProps {
  kpiTemplateDetail: KpiTemplateDetail;
  actionDispatcher: ActionDispatcherApi;
  apiRef: React.MutableRefObject<GridApiPro> | undefined;
  data: RecordDetailView[];
  loading: boolean;
}

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("Asset-Records"),
};

const KpiTemplateRecordsTable = (props: KpiTemplateRecordsTableProps) => {
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <MetadataColumnProvider type="RecordDetailViewModel">
        {(columns) => (
          <StripedDataGrid
            apiRef={props.apiRef}
            density="compact"
            loading={props.loading}
            components={GridComponents}
            columns={columns}
            rows={props.data}
          />
        )}
      </MetadataColumnProvider>
    </Box>
  );
};
