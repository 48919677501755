import _ from "lodash";
import { AssetType, AssetTypes } from "./assetType";

export enum BusinessArea {
  Instrumentation = "Instrumentation",
  Machinery = "Machinery",
}

export const getBusinessAreaForAssetType = (assetType: AssetType): BusinessArea => {
  switch (assetType) {
    case AssetType.Machinery:
      return BusinessArea.Machinery;
    default:
      return BusinessArea.Instrumentation;
  }
};

export const BusinessAreaMap = _.groupBy(AssetTypes, (assetType) => getBusinessAreaForAssetType(assetType));
