import { CardMedia, Box } from "@mui/material";
import { IrisColors } from "app/mui/theme";
import customColors from "assets/customColors";
import { Attachment } from "attachments/models";
import { attachmentIsImage } from "attachments/utilities";
import { DocIcon } from "icons/DocIcon";
import { FileIcon } from "icons/FileIcon";
import { PdfIcon } from "icons/PdfIcon";
import { XlsIcon } from "icons/XlsIcon";

export const FileIconOrImage = (props: { attachment: Attachment }) => {
	const getFileIcon = (fileName: string): React.ReactNode => {
		const fileLower = fileName.toLowerCase();

		if (fileLower.includes(".doc")) {
			return <DocIcon fill={customColors.congressBlue} />;
		}

		if (fileLower.includes(".pdf")) {
			return <PdfIcon fill={customColors.redBerry} />;
		}

		if (fileLower.includes(".xls")) {
			return <XlsIcon fill={customColors.bilbao} />;
		}

		return <FileIcon fill={customColors.doveGray} />;
	};

	return attachmentIsImage(props.attachment.fileName) ? (
		<CardMedia component="img" height="120" image={props.attachment.thumbnailUri} alt={props.attachment.fileName} />
	) : (
		<Box
			sx={{
				height: "120px",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				background: IrisColors.gray100,
				overflow: "hidden",
				svg: {
					width: 64,
					height: 64,
				},
			}}
		>
			{getFileIcon(props.attachment.fileName)}
		</Box>
	);
};
