import { useSiteIdFromRoute } from "app/routes";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAssetDigestsSearchRequest } from "search";
import { useDebounce } from "usehooks-ts";
import { AutocompleteFormItem, AutocompleteFormItemProps } from "./AutocompleteFormItem";
import { AssetDigest } from "assets";
import { getBusinessAreaForAssetType } from "assets/models/businessArea";

interface MultiAssetSelectFormItemProps extends Omit<AutocompleteFormItemProps<true, false, AssetDigest>, "options"> {
  automatedValvesOnly?: boolean;
  selectedAssets?: AssetDigest[];
  disabledAssetIds?: number[] | null;
  disabled?: boolean;
}

const minSearchTextLength = 3;
export const MultiAssetSelectFormItem = (props: MultiAssetSelectFormItemProps) => {
  const siteId = useSiteIdFromRoute()!;

  const [options, setOptions] = useState<AssetDigest[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const { data, loading, call } = useAssetDigestsSearchRequest();

  const debouncedSearchText = useDebounce(inputValue, 250);

  useEffect(() => {
    if (debouncedSearchText.length >= minSearchTextLength) {
      call({
        query: debouncedSearchText,
        siteId: siteId,
        valvesOnly: props.automatedValvesOnly || false,
        businessArea: !!props.selectedAssets?.[0]?.assetType
          ? getBusinessAreaForAssetType(props.selectedAssets[0].assetType)
          : null,
      });
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    const results = _.orderBy(data?.results, (a) => a!.tag).map((a) => ({
      ...a,
      isDisabled: !!props.disabledAssetIds && _.some(props.disabledAssetIds!.filter((assetId) => a.id === assetId)),
    }));

    setOptions(results);
  }, [data]);

  return (
    <AutocompleteFormItem
      multiple
      options={options}
      getOptionLabel={(o) => o.tag}
      noOptionsText={inputValue.length < minSearchTextLength ? "start typing to search" : "no results"}
      onInputChange={(event, value, reason) => {
        setInputValue(value);
        if (!value) {
          setOptions([]);
        }
      }}
      loading={loading}
      autocompleteProps={{
        disabled: props.disabled,
      }}
      {...props}
    />
  );
};
