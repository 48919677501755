import React from "react";
import { Button, Card } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import paginationFactory from "react-bootstrap-table2-paginator";
import { formatRoutePath, Routes, useSiteIdFromRoute, useUserContext } from "app";
import { formatSortCaret } from "common";
import { Application, ApplicationDigest } from "./models";
import { ApplicationIcon } from "icons/ApplicationIcon";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CreateApplicationModal } from "applications/CreateApplicationModal";
import { useState } from "react";
import { useGetApplicationDigestsInSiteRequest } from "./api";

const dataColumns = [
   {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (applicationName: string, application: Application) => (
         <Link key={application.id} to={formatRoutePath(Routes.Application, application)}>
            {applicationName}
         </Link>
      ),
      headerClasses: "md-column",
   },
];

export const ApplicationList: React.FunctionComponent<{}> = (props) => {
   const siteId = useSiteIdFromRoute()!;
   const { userPermissions } = useUserContext();
   const [createApplicationModalOpen, setCreateApplicationModalOpen] = useState(false);
   const query = useGetApplicationDigestsInSiteRequest(siteId);
   const applications = query.data ?? [];
   const navigate = useNavigate();

   return (
      <div className="legacy_bootstrap">
         <Card body className="application-list">
            <title>SpartanPRO IRIS - Applications</title>

            <ToolkitProvider keyField="id" data={applications} columns={dataColumns}>
               {(toolkitProps: any) => (
                  <>
                     <div className="screen-title">
                        <div className="icon-and-title">
                           <ApplicationIcon />
                           <div>Applications</div>
                        </div>
                        <div className="screen-commands">
                           {userPermissions.userCanEditApplication && (
                              <Button color="secondary" onClick={() => setCreateApplicationModalOpen(true)}>
                                 Create an application
                              </Button>
                           )}
                        </div>
                     </div>

                     <div>
                        <label> Application(s) Count: {applications.length} </label>
                     </div>
                     <BootstrapTable
                        {...toolkitProps.baseProps}
                        classes="table-sm list-table"
                        remote={{ filter: true }}
                        loading={query.loading}
                        bootstrap4={true}
                        striped
                        bordered={false}
                        defaultSorted={[{ dataField: "name", order: "asc" }]}
                        defaultSortDirection="asc"
                        headerClass="header"
                        pagination={paginationFactory({
                           sizePerPage: 100,
                           hideSizePerPage: true,
                           hidePageListOnlyOnePage: true,
                        })}
                        noDataIndication={() =>
                           query.loading ? <div>Loading...</div> : <div>No applications found.</div>
                        }
                     />
                     {userPermissions.userCanEditApplication && createApplicationModalOpen && (
                        <CreateApplicationModal
                           key="application-list-create-application-modal"
                           close={() => setCreateApplicationModalOpen(false)}
                           createdApplication={(newApplication: ApplicationDigest) => {
                              setCreateApplicationModalOpen(false);
                              navigate(formatRoutePath(Routes.Application, newApplication));
                           }}
                        />
                     )}
                  </>
               )}
            </ToolkitProvider>
         </Card>
      </div>
   );
};
