import * as React from "react";
import { useEffect, useState } from "react";
import { Box, ButtonBase, styled, Typography } from "@mui/material";
import { IrisColors } from "app/mui/theme";

interface ActiveObsoleteStatusChipProps {
   isObsolete?: boolean;
   onChange?: (newValue: boolean) => void;
   disabled?: boolean;
}

export const ActiveObsoleteStatusChip = (props: ActiveObsoleteStatusChipProps) => {
   const [isObsolete, setIsObsolete] = useState<boolean>(props.isObsolete || false);

   const handleClick = () => {
      if (props.disabled) return;

      const nextValue = !isObsolete;
      setIsObsolete(nextValue);
      props.onChange?.(nextValue);
   };

   useEffect(() => {
      setIsObsolete(props.isObsolete || false);
   }, [props.isObsolete]);

   return (
      <Container disabled={props.disabled} disableRipple onClick={handleClick}>
         <BackgroundHighlight sx={{ left: isObsolete ? "50%" : "0%" }} />
         <Item>
            <Typography sx={{ fontWeight: "bold", fontSize: "0.85rem" }} color={!isObsolete ? "#fff" : "primary.dark"}>
               Active
            </Typography>
         </Item>
         <Item>
            <Typography sx={{ fontWeight: "bold", fontSize: "0.85rem" }} color={isObsolete ? "#fff" : "primary.dark"}>
               Obsolete
            </Typography>
         </Item>
      </Container>
   );
};

const Container = styled(ButtonBase)(({ theme }) => ({
   position: "relative",
   borderRadius: "25px",
   display: "flex",
   width: "170px",
   background: IrisColors.gray100,
   boxShadow: "inset 0px 0px 2px rgba(0,0,0,0.3)",
}));

const Item = styled(Box)(({ theme }) => ({
   position: "relative",
   flex: "1",
   zIndex: 2,
   width: "50%",
   paddingTop: "2px",
   paddingBottom: "2px",
   textAlign: "center",
}));

const BackgroundHighlight = styled("div")(({ theme }) => ({
   position: "absolute",
   zIndex: 1,
   background: IrisColors.legacyTertiaryGray,
   borderRadius: "25px",
   height: "100%",
   width: "50%",
   transition: "all 0.1s ease-out",
}));
